/* Set height to 100% for body and html to enable the background image to cover the whole page: */
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
    236deg,
    rgba(108, 97, 68, 0.3),
    rgba(190, 232, 22, 0.3),
    rgba(146, 172, 43, 0.3),
    rgba(223, 147, 41, 0.3)
  );
  background-size: 800% 800%;
  animation: gradientAnimation 19s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 99% 0%;
  }
  50% {
    background-position: 2% 100%;
  }
  100% {
    background-position: 99% 0%;
  }
}

ion-icon {
  transition: all 0.3s ease-in-out;
}

ion-icon:hover {
}

.hero-name {
  font-size: 2.7rem;
  font-weight: bold;
  color: rgb(26, 26, 26);
  top: 8%;
  left: 50%;
  z-index: 3;
  text-transform: uppercase;
}
.hero-name span {
  color: rgb(26, 26, 26);
}

.home {
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 2;
  border-radius: 10px;
  padding: 1rem;
  cursor: url(./moveCursor.png), grab;
}

canvas {
  cursor: url(./moveCursor.png), grab !important;
  color: white;
}

.home-title {
  /* height: 7rem; */
  transition: all 0.5s ease-in-out;
  
  
  
  text-transform: uppercase;
}
.home-title span {
  user-select: none;
  transition: all 0.5s ease-in-out;
  font-size: 30px;
  line-height: 1.3;
  font-weight: bold;
}

.home-title-span-animation {
  transition: all 0.3s ease-in-out;
  background-color: rgba(255, 255, 255, 0.242);
  padding: 0.1rem;
  border-radius: 5px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .home-title {
    margin-top: -8rem;
  }

  .home-title span {
    font-size: 25px;
  }

  .home-video-controls {
    margin-top: 4.4rem;
  }
}

.home-video-controls {
  position: fixed;
  bottom: 1.5rem;
  left: 1rem;
  z-index: 10;
  backdrop-filter: blur(5px);
  background: none;
  border-radius: 5px;
  padding: 0.25rem;
  height: 2.5rem;
  width: 6rem;
}

.press-play-tooltip {
  position: absolute;
  bottom: 3rem;
  left: 0.1rem;
  width: 90%;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.2rem 0.35rem 0.2rem 0.35rem;
  border-radius: 5px;
  backdrop-filter: blur(5px);
  color: white;
  align-items: center;
  z-index: 10;
  transition: all 0.3s ease-in-out;
}

.press-play-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 15%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  transform: rotate(180deg) scaleX(-1);
  border-color: transparent transparent rgba(255, 255, 255, 0.1) transparent;
  backdrop-filter: blur(5px);
}

.home p {
  font-size: 1.2rem;
  font-weight: bold;
}

.glass {
  border-radius: 5px;
  border: #16161649 solid 1px;
  padding: 0.3rem;
}

.home {
  position: absolute;
  top: 15rem !important;
}

.about {
  height: 90vh;
  overflow: scroll;
}

.ncbceInterview {
  width: 100% !important;
  height: 100% !important;
  margin-top: 20px;
}

.ncbceInterview video {
  border-radius: 7px;
}

.mediaPlayerVideo {
  border-radius: 15px;
  width: 10rem;
  height: 22rem;
  margin-left: 30%;
  transform: translateX(-50%) scale(1.2);
  margin-top: 1rem;
}

.work {
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 24%;
}

.work-title {
  text-align: center;
}

.work-content-item {
  transition: all 0.3s ease-in-out;
  padding: 1rem;
}

.work-content-item a {
  text-decoration: none;
  /* color: rgb(26, 26, 26); */
  font-size: 1.2rem;
}

.work-content-item:hover {
  background: rgba(70, 70, 70, 0.7);
  color: white;
  cursor: pointer;
}

.work-collection {
  overflow-y: scroll;
  height: 55vh;
}

.a-loader-title {
  display: none !important;
}

/* Position text in the top-left corner */
.topleft {
  position: fixed;
  top: 1rem;
  left: 1rem;
  width: 80%;
}

.topleft a {
  text-decoration: none;
  font-weight: 600;
}

.topleft a > p {
  text-transform: uppercase;
}

.header {
  position: fixed;
  width: 100vw;
  z-index: 3 !important;
}

.dropbtn {
  background: none;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  top: -1rem;
  position: relative;
}

.dropdown {
  position: fixed;
  top: 1rem;
  right: 0.5rem;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
/* Dropdown Content (Hidden by Default) */

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content {
  display: none;
  position: absolute;
  width: 135px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 5px;
  z-index: 1;
  right: 0;
  top: 2.5rem;
}

/* Links inside the dropdown */
.dropdown-content a {
  padding: 12px 14px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:first-child:hover {
  border-radius: 4px 4px 0 0;
}

.dropdown-content a:last-child {
  border-bottom: none;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  transition: all 0.3s ease-in-out;
  background: black;
  color: white;
}

.nav-button {
  position: relative;
  height: 2rem;
  width: 2.1rem;
  background-color: transparent;
  background-image: linear-gradient(to right, currentColor, currentColor);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 5px;
  padding: 0;
  outline: 0;
  border: 0;
  color: black;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: 0.25s cubic-bezier(0.17, 0.67, 0.89, 1.4);
  transition-property: transform;
  will-change: transform;
}

.nav-button::before,
.nav-button::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  height: 5px;
  background: currentColor;
  transition: 0.25s;
  transition-property: transform, top;
  will-change: transform, top;
}

.nav-button::before {
  top: 0;
}

.nav-button::after {
  top: calc(100% - 4px);
}

/* arrow button 3 */
.arrow-button-3:focus {
  transform: rotate(-90deg) translateZ(0);
}

.arrow-button-3:focus::before {
  width: 65%;
  transform: rotate(-45deg) translate3d(-48%, 0, 0);
}

.arrow-button-3:focus::after {
  width: 65%;
  transform: rotate(45deg) translate3d(-48%, 0, 0);
}

.nk-footer {
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  max-height: 40px;
}

.bottomleft a {
  color: white;
}

.footerContent {
  margin-top: -0.5rem;
}

.bottomleft {
  position: absolute;
  left: 1rem;
  bottom: 0;
  margin-bottom: 1rem;
  padding: 0.4rem;
  color: black;
  z-index: 4;
}

.bottomleft a {
  text-decoration: none;
  font-size: 1.2rem;
  color: white;
  color: black;
  margin-right: 1rem;
}

.bottomRight {
  position: fixed;
  bottom: 0rem;
  right: 1.5rem;
  margin-bottom: 1rem;
  transition: all 0.25s ease-in-out;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(80, 80, 82, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 0.3rem;
  color: black;
}

.a-enter-vr-button {
  background: none !important;
  color: white !important;
  border-radius: 5px !important;
  padding-top: 2px !important;
  backdrop-filter: blur(5px) !important;
  position: fixed !important;
  bottom: 1.5rem !important;
  right: 1.5rem !important;
}
.a-enter-vr-button:hover {
  color: white !important;
}
.a-enter-vr-button::before {
  content: "VR";
  font-size: 1.5rem;
}

.a-enter-vr {
  z-index: 10;
}

.connect-button {
  position: fixed;
  top: 0.7rem;
  right: 4rem;
  margin-bottom: 1rem;
  transition: all 0.25s ease-in-out;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(142, 142, 179, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 0.3rem 0.5rem;
}

.disconnect-button {
  padding: 0.1rem 0.5rem;
}

.mediaBtn {
  background: rgba(255, 255, 255, 0.442) !important;
  border-radius: 50%;
}

.watermark {
  display: none !important;
}

.contact {
  transform: translateX(-50%);
  width: 90%;
  position: relative;
  top: 5rem;
}

@media screen and (max-width: 1000px) {
  .contactInfo {
    width: 100%;
  }
}

.contactTitle {
  text-align: center;
  padding: 1rem;
}

.contactInfo {
  margin-left: 50%;
  text-align: center;
  transform: translateX(-50%);
}

.contact-form {
  margin: 0 1rem;
}

.contact-input {
  border-radius: 4px;
  border: 1px solid black !important;
  color: black;
  font-size: 1rem;
}

.contact-input::placeholder {
  color: rgba(32, 32, 32, 0.904);
  font-style: italic;
}

.contact-textarea {
  margin-bottom: 1rem;
  border: none;
  border: 1px solid black;
  border-radius: 4px;
  resize: none;
  font-size: 1rem;
}

.contact-textarea::placeholder {
  color: rgba(32, 32, 32, 0.904);
  font-style: italic;
}

.contact-submit-btn {
  width: 100%;
  border: none;
  font-size: 1rem;
  color: white;
  background: black !important;
  font-weight: bold;
  border-radius: 4px;
}

.blogItem {
  height: 3rem;
  text-align: center;
}

.link {
  z-index: 4;
}

@media screen and (max-width: 1250px) {
  .homeBody {
    font-size: 14px;
    line-height: 1.6rem;
  }
  .homeBodyText {
    width: 90vw;
  }
  .experimentsContent {
    text-align: left;
    width: 90vw;
  }
  .my-form {
    width: 90vw;
  }
}

@media screen and (max-width: 500px) {
  small {
    display: block !important;
  }
}

ion-icon:hover {
  cursor: pointer;
}

.content {
  position: absolute;
  z-index: 1;
  color: black;
  padding: 15px;
  left: 50%;
  transform: translateX(-50%);
  top: 8rem;
  padding: 1rem;
}

.content-darker {
  background: rgba(0, 0, 0, 0.55);
  width: 30%;
  top: 10rem;
}

.contentButtons {
  justify-content: space-around;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.contentButton {
  position: relative;
  z-index: 2;
  color: black;
  border: none;
  padding: 10px;
  background: rgba(255, 255, 255, 0.15);
  text-decoration: none;
  font-style: none;
  padding: 15px;
  margin-right: 15px;
}

.contactButton:hover {
  text-decoration: none;
  color: black;
}

.blog {
  position: absolute;
  top: 5rem;
  width: 70%;
  height: 100%;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  top: 5rem;
}

.blog > a {
  text-decoration: none;
  color: black;
  font-size: 1rem;
  padding: 1rem;
  margin: 10px;
}

.muteButton {
  position: absolute;
  z-index: 1;
  color: white;
  padding: 15px;
  margin-top: 50%;
  transform: translateY(-50%);
  padding-top: 3rem;
  right: 7px;
}

@media screen and (max-width: 1000px) {
  .muteButton {
    margin-top: 550px;
    transform: translateY(0%);
  }
}

form input {
  width: 50%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  border: 2px solid #ccc;
  background: none;
}

form textarea {
  width: 50%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  border: 2px solid #ccc;
  background: none;
}

form button {
  width: 50%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: all 0.3s ease-in-out;
  background: rgba(0, 0, 0, 0.9);
  color: white;
}

form button:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.7);
}

#galleryContent {
  animation: galleryContent 0.2s ease-in-out 3s infinite linear;
}

@media screen and (max-width: 620px) {
  #galleryContent {
    width: 90%;
    top: 5rem;
  }
}

@keyframes galleryContent {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

ion-icon[color="white"] {
  color: white;
}

.secretForm input {
  width: 60%;
  margin-left: 50%;
  transform: translateX(-50%);
}

.secretForm button {
  width: 60%;
  margin-left: 50%;
  transform: translateX(-50%);
}

.secretConnectBtn {
  position: absolute;
  z-index: 10;
  text-align: center;
  border-radius: 10px;
  color: white;
  top: 20rem;
  left: 50%;
  transform: translateX(-50%);
  background: black;
  padding: 15px;
  font-size: 1.25rem;
  /* text-transform: uppercase; */
}

.markerImg {
  width: 60%;
  /* margin-left: 50%;
  transform: translateX(-50%); */
  z-index: 10;
  opacity: 1;
  visibility: visible;
  display: block;
}

.mediaPlayer {
  overflow-y: scroll;
  height: 90vh;
}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #000000e0 0%,
    #fdfdfdd2 10%,
    /* #13a1ff 80%, */
    /* #104106 60%, */
    #ff8000e4 90%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 10s linear infinite;
  display: inline-block;
  font-weight: bold;
  /* font-size: 30px; */
  line-height: 1.3;
      /* font-size: 190px; */
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}


#splash {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 200px;
  height: 200px;

  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  width: 64px;
  height: 64px;
  position: relative;
  top: 400px;
  border-radius: 50%;
  border: 0.25rem solid rgba(255, 255, 255, 0.2);
  border-top-color: white;
  animation: spin 1s infinite linear;
}